@import "./themes/themes";

/* Start Variables */
$content-background-color: #fafbfc;
//$table-box-shadow-selectable: 37px 0px 4px rgba(0, 0, 0, 10%) !important;
//$table-box-shadow-not-selectable: 0px 0px 4px rgba(0, 0, 0, 10%) !important;
/* Create a gradient to exclude the checkbox container from the table background */
//$background-linear-not-selectable: linear-gradient(to left,
//    #fff 0%,
//    #fff calc(100% - 1px),
//    rgba(0, 0, 0, 0.1) calc(100% - 2px),
//    transparent calc(100% - -1px),
//    transparent 100%) !important;
//$background-linear-selectable: linear-gradient(to left,
//    #fff 0%,
//    #fff calc(100% - 39px),
//    rgba(0, 0, 0, 10%) calc(100% - 40px),
//    transparent calc(100% - 37px),
//    transparent 100%) !important;

/* End Variables */

/* End Custom ScrollBar */
.sxw-theme {
  .p-checkbox {
    .p-checkbox-box.p-highlight.p-disabled {
      background: #afafaf !important;
      border-color: #afafaf !important;
    }
  }
  // Table Styles
  &::-webkit-scrollbar {
    display: none !important;
  }

  &.card {
    overflow-y: scroll;
    max-height: 100%;
    height: 100%;
  }

  // Calendar Styles
  .p-calendar-w-btn .p-datepicker-trigger {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0px;
    //margin-top: 5px;
  }

  /* Progress Bar */
  .p-progressbar-indeterminate {
    height: 0.2rem;
    border-radius: 0;

    .p-progressbar-value {
      background: #b51313;
    }
  }

  .p-progressbar {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 999;
  }

  // Tree Styles
  .p-treenode-label {
    flex: 1;
  }

  // DropDown Styles
  .p-dropdown,
  .sxw-theme .p-multiselect {
    border-radius: 4px;
    //margin-top: 5px;
  }

  .p-multiselect-token {
    padding: 4px 6px;
    height: 30px;
    margin-right: 7px;
  }

  // Input Styles
  .sxw-theme .p-inputtext {
    border-radius: 4px;
    //margin-top: 5px;
    // padding: 10px;
  }

  // Button Styles
  .p-button {
    margin: 4px;
    text-align: center;
    padding: 10px;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 0px 6px #00000029;
    cursor: pointer;
    border: 0px;
    background: #252d40;
  }

  .p-button:enabled:hover {
    background: #333e5e;
  }

  // Filter Inputs Styles
  .filter-body .p-inputtext,
  .filter-body .p-dropdown-items {
    font-size: 15px !important;
    // padding: 8px 10px !important;
  }

  // Tree
  .p-tree {
    overflow-x: auto;

    .p-tree-container {
      overflow: hidden;
    }
  }

  .p-tree .p-treenode-children {
    padding: 0 0 0 15px;
    border-left: 1px solid #d9d9d9;
  }

  .p-tree .p-treenode-content {
    padding: 5px 0 !important;
  }

  .p-tree .p-tree-toggler {
    margin-right: 0 !important;
  }

  .p-tree .p-tree-toggler:hover {
    background: transparent !important;
  }

  .p-tree .p-tree-toggler:focus {
    box-shadow: none !important;
  }

  .p-tree .p-tree-container .p-treenode {
    padding: 0;
  }

  .form {
    height: 100%;
  }
}

.sxw-theme.form-container {
  .form-container,
  .form-container-config {
    /* To fix box-shadow hidden issue, box-shadow spread is 4px so making margin, and padding like box-shadow
     https://medium.com/@timothyde/using-overflow-y-while-retaining-shadows-de5938f8fe1b
    */
    margin-inline: -4px;
    padding-inline: 4px;
    padding-bottom: 4px;
    z-index: 1;
    /* ******************************************************************************* */
  }
}

/* Standard-km theme */
.standard-km {
  &.form-container {
    //height: calc(100% - 120px);
    .form-container {
      /* To fix box-shadow hidden issue, box-shadow spread is 4px so making margin, and padding like box-shadow
       https://medium.com/@timothyde/using-overflow-y-while-retaining-shadows-de5938f8fe1b
      */
      margin-inline: -4px;
      padding-inline: 4px;
      padding-bottom: 4px;
      /* ******************************************************************************* */
    }
  }
  .form {
    height: 100%;
  }
  //&.simple-summary-container {
  //  padding-top: 4px !important;
  //}
}

.p-inputtext:enabled:focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #bfdbfe !important;
  border-color: #3b82f6 !important;
}

.sxw-theme-dark {
  //  {---------Strat global theme variables--------}
  $background: rgb(207 208 209 / 100%);
  $border: 1px solid #ced4da;
  $transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  $border-radius: 6px;
  $color: black;
  $font-weight: 500;
  $border-color: #8c8b8b54;
  //  {---------End global theme variables--------}

  .p-dropdown,
  .p-multiselect {
    background: $background;
    border: none !important;
    transition: $transition;
    border-radius: $border-radius;
    height: 100%;

    .p-dropdown-label.p-placeholder {
      color: $color !important;
      font-weight: $font-weight;
    }
  }

  .full-width-input,
  .full-width-input input,
  .full-width-input textarea,
  .full-width-input select,
  .full-width-input .p-inputnumber {
    width: 100%;
    resize: none;
    border-radius: $border-radius;
  }

  .p-focus:not(.p-disabled),
  input:focus,
  input:hover,
  .p-dropdown:not(.p-disabled):hover,
  .p-multiselect:not(.p-disabled):hover {
    border: none !important;
  }
}

.sxw-theme-light {
  //  {---------Strat global theme variables--------}
  $background: transparent;
  $border: 1px solid #ced4da;
  $transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  $border-radius: 6px;
  $color: black;
  $font-weight: 400;
  $border-color: rgb(233 235 239 / 100%);
  //  {---------End global theme variables--------}

  .p-dropdown,
  .p-multiselect {
    background: $background;
    border: $border;
    transition: $transition;
    border-radius: $border-radius;
    height: 100%;

    .p-dropdown-label.p-placeholder {
      color: #6c757d !important;
      font-weight: 700;
    }
  }

  .full-width-input,
  .full-width-input input,
  .full-width-input textarea,
  .full-width-input select,
  .full-width-input .p-inputnumber {
    border-radius: $border-radius;
    border-color: $border-color;
  }

  .p-inputnumber-input,
  .p-dropdown-label {
    font-weight: 700 !important;
  }
}

.sxw-custom-autoComplete {
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: rgb(37 45 64 / 100%);
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    padding: 1rem 0.75rem 1rem 0.75rem;
    position: relative;
  }

  @media screen and (max-width: 1444px) {
    .p-multiselect-token-label {
      position: relative;
      bottom: 1px;
    }
  }

  .p-multiselect-panel .p-multiselect-header {
    display: none !important;
  }

  .p-multiselect.p-multiselect-chip
    .p-multiselect-token
    .p-multiselect-token-icon {
    background-color: rgb(230, 118, 118);
    font-family: inherit;
    border-radius: 50%;
    width: 18px;
    text-align: center;
    font-weight: bold;
    padding: 1px 1px;
    margin-left: 20px;

    @media screen and (max-width: 1444px) {
      position: relative;
      bottom: 1px;
    }

    &::before {
      position: relative;
      content: "x";
      bottom: 0.7px;
    }
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    display: none !important;
  }

  .p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: rgb(250 251 252 / 100%);
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: rgb(250 251 252 / 100%);
    color: rgb(37 45 64 / 100%);
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    font-weight: bold !important;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
  }
}

.sxw-static-tree-node {
  .sxw-static-tree-node {
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translatey(-50%);
      border: 0.2rem solid #222;
      border-radius: 50%;
    }
    position: relative;
    padding-left: 18px !important;
  }
  .p-tree-toggler {
    display: none;
  }

  .p-treenode-content {
    padding: 0.8rem !important;
  }
}

.sxw-custom-date-picker {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 10px;

  .full-width-input select {
    width: 75px;

    @media screen and (min-width: 1100px) {
      width: 125px;
    }
  }

  & > div {
    width: 75px;

    @media screen and (min-width: 1100px) {
      width: 125px;
    }
  }

  .time-picker-label {
    position: absolute;
    top: -16px;
    left: 2px;
    font-size: 12px;
    font-weight: 500;
    color: #ababab;
    margin-bottom: 3px;
    text-transform: capitalize;
  }

  .p-calendar .p-inputtext,
  .p-dropdown-label {
    font-weight: 700 !important;
  }
}

.sxw-two-column-date-picker {
  display: grid;
  grid-template-columns: 50% 49%;
  gap: 5px;

  .p-inputtext,
  .p-dropdown,
  .p-multiselect {
    border-radius: 4px !important;

    .p-dropdown-trigger {
      display: none;
    }
  }

  .time-picker-container {
    position: relative;

    .time-picker-label {
      position: absolute;
      top: -25px;
      left: 3px;
      color: #212121;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.custom-submit-button-2 {
  &.no-hover {
    .p-button:enabled:hover,
    .p-button:enabled:hover {
      background: #252d40;
    }
  }

  &.diff-bg {
    &.no-hover {
      .p-button:enabled:hover,
      .p-button:enabled:hover {
        background: #575151;
      }
    }

    & > button {
      background-color: #575151;
    }
  }

  min-width: 65px;
  max-width: 65px;
  display: block !important;

  & > .p-button {
    justify-content: center !important;
  }

  & > button {
    padding: 8px 10px !important;
    width: 100%;

    & > span:nth-child(2) {
      display: none;
    }
  }
}

.custom-error-container-text-area {
  .error {
    position: absolute;
  }
}

.list-cards.select-container {
  .p-checkbox .p-checkbox-box {
    border-radius: 12px;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border: none !important;
    background: #252d40;
    padding: 11px;
    z-index: 3;
  }
}

.sidebar {
  .p-sidebar .p-sidebar-header,
  .p-sidebar .p-sidebar-footer {
    display: none;
  }
}

.news-view-dialog {
  background: #fafbfc !important;
  height: 100%;

  & .p-sidebar-content {
    padding: 5px !important;
  }

  .header {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 0 4px #00000029;
    border-radius: 8px;
    opacity: 1;
  }
}

//////////////////////////////////////////////
// custom style for status dropdown in iew lead //

.sxw-lead-status-theme {
  & p-dropdown .p-dropdown {
    & .p-dropdown-trigger {
      display: none;
    }
  }
}

/////////////////////////////////////////////
// custom style specific for view lead page //
.sxw-action-view-lead {
  & p-dropdown .p-dropdown {
    padding: 0;
    height: 28px;
    min-height: initial !important;
    width: max-content;
    border: none !important;
    border-radius: 8px;
    box-shadow: 0 0 2px 0 #868686;
    font-weight: 600;
    margin: 0 0.3rem;

    & .p-dropdown-label {
      color: #000000;
      padding: 0 0 0 1.9rem;
      line-height: 2.2;
      font-size: 12px;
      font-weight: 700;
      &::before {
        content: "";
        background-image: url("../images/icons/Icon-awesome-book.svg");
        width: 16px;
        background-repeat: no-repeat;
        height: 19px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        z-index: 99;
      }
    }

    & .p-dropdown-trigger {
      font-weight: 700;
    }

    & .p-dropdown-panel {
      min-width: 395px !important;
      top: 45px !important;
      left: -7rem !important;
      padding: 0.2rem;
      @media screen and (max-width: 768px) {
        min-width: 269px !important;
      }

      & .p-dropdown-items {
        padding: 1rem 2rem !important;
        @media screen and (max-width: 768px) {
          padding: 1rem 1rem !important;
        }
      }

      & .p-scroller {
        &::-webkit-scrollbar {
          display: none;
        }
      }

      & :is(span) {
        padding: 0.6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 7px;
        margin-left: 1.3rem;
      }

      & .p-dropdown-item {
        box-shadow: 0 0 3px 1px #e2e2e2 !important;
        margin: 0.4rem 0 !important;
      }

      & li:is(.p-highlight) {
        color: #000000;
        background: #f3f3f3;
      }

      & .p-dropdown-item span::before {
        content: "";
        background-image: url("../images/icons/Icon-awesome-book.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        position: absolute;
        left: 5%;
        bottom: 25%;
      }
    }
  }

  p-dropdown .p-dropdown:not(.p-disabled):hover {
    border: none !important;
    box-shadow: 0px 0px 3px 1px #e2e2e2 !important;
  }

  p-dropdown .p-dropdown:not(.p-disabled):active {
    border: none !important;
    box-shadow: 0px 0px 3px 1px #e2e2e2 !important;
  }
}

.sxw-tag-lead-theme {
  & p-checkbox .p-checkbox .p-checkbox-box.p-highlight {
    color: white;
    background: #8b8c8f;
    border-color: #8b8c8f;
  }

  & p-checkbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #8b8c8f;
    background: #9fa1a4;
  }

  @extend .sxw-theme;

  .p-button {
    transition: all 0.3s ease-in-out;

    &:focus {
      border: none !important;
      outline: unset !important;
      box-shadow: none !important;
    }

    &:is(.log-lead) {
      background: #ffffff;
      color: #000000;
    }

    &:is(.smooth) {
      width: 70px !important;
    }

    &:is(.log-lead):hover {
      background: initial !important;
      color: initial;
    }

    &:is(.cancel-button) {
      background: #4c4d4c;
    }

    width: 90px;
    padding: 7px;
    font-size: 17px;
    display: block;
  }
}

.overly-pipe-line-lead {
  padding: 0.65rem 1rem;
  color: #a2a3a5 !important;
  width: 180px;
}

.sxw-pipe-line-lead-view {
  & p-dropdown .p-dropdown {
    font-size: 16px;
    font-weight: 600;
    // width: 230px;
    margin: 6px 0px;
    border-color: #eeeff2;

    & span:is(.p-dropdown-label) {
      padding: 0.3rem 0.5rem;
      font-size: 12px;
    }

    & .p-dropdown-panel {
      & li:is(.p-highlight) {
        color: #000000;
        background: #f3f3f3;
      }

      width: 100px;
    }

    & .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      // padding: 0.3rem 1rem;
      font-size: 12px;
    }

    &:hover {
      border-color: #c6c6c9 !important;
    }
  }

  .p-dropdown-items-wrapper,
  .p-scroller {
    min-width: 100px !important;
  }
}

.lead-activity {
  @extend .sxw-theme-dark;

  & p-dropdown .p-dropdown {
    background: #eefafe;
  }
}

.performed-activity-wrapper {
  height: fit-content !important;
  padding: 0.5rem;
  border: 1px solid #8c8b8b54;
  border-radius: 10px;
  margin: 0.7rem 0;
  margin-inline: 0px !important;

  & .view-lead-activity {
    & p-dropdown .p-dropdown {
      background: #eefafe;
      color: #000000;
      font-weight: 600;

      & .p-dropdown-label {
        font-size: 14px;
        padding: 0.5rem 0 0.5rem 0.75rem;
      }
    }
  }
}

.custom-submit-button .p-button {
  justify-content: center;
  background: black;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 5px 17px;
  opacity: 1 !important;
  font-weight: 600;
  transition: all 0.4s ease-in-out;

  & .p-button-label {
    display: none;
  }

  &:hover {
    background: #333e5e;
    color: #ffffff;
  }
}

.view-lead-body {
  background: #fafbfc !important;

  & .sidebar__content {
    margin-top: 2rem;
    overflow: initial;
  }

  & .sidebar__content::-webkit-scrollbar {
    display: none !important;
  }
}

.sxw-multi-columns-list-cards {
  z-index: 1 !important;

  .centered {
    .cards-container {
      align-self: start !important;
      width: 100% !important;
    }
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    .p-checkbox-icon {
      font-size: 12px !important;
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  .card-action-button:hover {
    background: transparent !important;
  }
  .p-checkbox,
  .p-checkbox-box {
    .p-checkbox-checked {
      background-color: red !important;
    }
    .list-cards.select-container .p-checkbox .p-checkbox-box.p-highlight {
      padding: 0;
    }
    .p-checkbox-icon .pi .pi-check {
      font-size: 10px !important;
    }
  }
}

.position-relative {
  position: relative;
}

.sxw-pipeline-lead-picker {
  & p-colorPicker {
    position: absolute;
    top: 25px;
    right: 0px;

    & .p-colorpicker-overlay .p-colorpicker-preview {
      width: 55px;
      height: 47px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border: unset;
    }
  }
}

.p-dropdown-items-wrapper,
.p-scroller {
  min-width: 200px !important;
}

.mobile-number .p-dropdown-items-wrapper {
  min-width: 300px !important;
}

.mobile-number.sxw-theme {
  & .p-element .p-dropdown-trigger {
    width: 1.5rem;
    justify-content: start;
  }
  & .p-dropdown-label {
    padding: 10px;
  }
}

.mobile-number.sxw-theme .input-container span {
  // top: -1px;
  transform: translateY(0);
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Segoe UI Symbol;
  font-size: 1rem;
  padding: 0.75rem 0;
}

.no-margin {
  .p-button {
    margin: 0px;
  }
}

.white-back {
  .p-button {
    background-color: #fff;
  }
}

.p-relative {
  position: relative;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.5rem 0.75rem !important;
}

.multiple {
  .p-multiselect .p-multiselect-label {
    padding: 0.7rem 0.75rem;
  }

  .p-inputwrapper-filled
    .p-multiselect.p-multiselect-chip
    .p-multiselect-label {
    padding: 0.49rem 0.75rem;
  }
}

.p-multiselect-label-container {
  width: 200px;
}

.p-multiselect-label {
  text-overflow: clip !important;
}
.file-input-button-design .p-button {
  position: absolute;
  right: 1%;
  padding: 7px;
  text-align: center;
  font-size: 12px;
  top: 5px;
  background: #d2c83f;
  color: #252d40;
  font-weight: 700;
  border-radius: 11px;
}
.file-input-button-design .p-button:focus {
  box-shadow: none;
}
.file-input-design .p-inputtext {
  border-radius: 10px !important;
  background: #e9ebef !important;
  padding-left: 2.5rem !important;
}

.m-t {
  margin-top: 5px;
}

.mb-10 {
  margin-bottom: 13px;
}

.fw-b {
  font-weight: 700;
}

.form-popup {
  form {
    height: 100%;
  }
  & > .p-dialog-header {
    padding: 0.5rem 1rem !important;
  }

  & > .p-dialog-content {
    padding: 0 5px 0 5px !important;
  }
  .form-layout {
    padding: 0 10px 0 0 !important;
  }
  .form-path {
    box-shadow: none !important;
    padding: 0 !important;
    height: auto !important;
    min-height: auto !important;

    & > h3 {
      margin: 10px;
    }
  }
  .step-content {
    box-shadow: none !important;
    .content-container {
      padding: 0 20px;
      height: 90%;
      max-height: 90%;
    }
  }
  .content-container {
    box-shadow: none !important;
  }
}
.view-vehicle-ST-side-dialog {
  .sidebar__content {
    overflow: hidden;
  }
}

// Autocomplete address component overlay container
// The z-index was added to make sure it appears above every thing even when the input is loaded in a popup
.pac-container {
  z-index: 9999;
}

.p-overlaypanel {
  margin-top: 5px !important;
  margin-left: -8px;
}
.sxw-opening-time-theme {
  & .p-dropdown {
    width: 100%;
  }
}
.sxw-opening-times-error-msg {
  background-color: #db5b2d;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
}
.sxw-opening-times-error-msg .error__msg {
  color: #e2e2e2 !important;
  animation: colorAnimation 1.1s alternate-reverse infinite;
  transition: all 0.5s ease-in-out;
}
@keyframes colorAnimation {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

// .max-width {
//   max-width: 330px;
// }
/* start Summary table */
.table-overflow {
  overflow: overlay;
}

.table-overflow::-webkit-scrollbar {
  height: 5px;
}

.summary-table {
  border-collapse: collapse;
  width: 100%;
}

.summary-table tr {
  border: 1px solid #e9ebef;
}

.summary-table thead tr:nth-child(1) {
  background-color: #e9ebef;
  padding: 10px;
}

.summary-table th,
.summary-table td {
  padding: 10px;
  text-align: center;
}

.summary-group-header {
  border-bottom: 1px solid #252d40;
  padding-bottom: 5px;
}

.multiple-value {
  display: flex !important;
  overflow: visible !important;
  align-items: flex-end !important;
  flex-wrap: wrap;
}

/* End Summary table */

.p-toast .p-toast-message.p-toast-message-error {
  border: 0px !important;
}

.sxw-theme .full-width-input .p-treeselect {
  width: 100%;
}

.filter-body.shown {
  width: 720px !important;
}
.hidden {
  .p-dialog-content {
    overflow-y: hidden;
    height: 100vh;
  }
}

.scroll-form {
  overflow-y: scroll;
  padding: 0px 10px;
}
.sxw-input {
  .p-inputtext {
    height: 44px;
  }
}

.imgComponent {
  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox .p-checkbox-box {
    margin-top: 4px;
  }
}
.status-item.normal-cursor {
  cursor: default !important;
}
.p-icon-wrapper {
  display: flex !important;
}

/*style for reservation  */
sxw-tab-filters .sxw-theme-reservation {
  .tab-filters {
    display: flex;
    align-items: flex-end;
    height: auto;
    margin: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 0;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 25px;
    color: #7c7c7c;
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    flex-wrap: wrap;
  }

  .tab-filter {
    background-color: transparent;
    cursor: pointer;
    padding: 0px 12px;
    box-shadow: 0 0 4px #00000029;
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    .tab-filter {
      font-size: 13px;
    }
  }

  .tab-filter.active {
    font-weight: 600;
    font-size: 0.8rem;
    color: #1f4e34;
    border-radius: 5px;
    border: 1px solid #878b96;
    &::after {
      all: unset;
    }
  }

  @media screen and (max-width: 768px) {
    .tab-filter.active {
      font-size: 13px;
    }
  }
}

.selected-car-class {
  text-align: left;
  margin-left: 0.5rem;
}

.selected-car-features {
  display: flex;
  flex-wrap: wrap;

  &-text {
    background: #e9ebef 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    font: normal 450 1em/1.5em Poppins;
    color: #212121;
    opacity: 1;
    margin: 0.5rem;
    padding: 0.5rem;
  }
}

.selected-car-price {
  font: normal 600 15px/23px Poppins;
  color: #8b8c8f;
  margin: 0px 0.7rem;
}

.unselected-car {
  display: none;
}
/*end style for reservation  */
.p-dropdown {
  min-height: 48px !important;
}
.p-calendar {
  height: -webkit-min-available !important;
}
.p-sidebar .p-sidebar-content {
  padding-right: 0rem !important;
}

@media (max-width: 500px) {
  li:not(:last-child) .bg-step.done::after {
    rotate: 90deg !important;
    transform: rotate(0deg) !important;
    right: -19px !important;
  }
  .lead-header__right {
    justify-content: center !important;
    padding-top: 10px;
  }
  .lead-header {
    display: block !important;
  }
}
@media screen and (max-width: 786px) {
  .car-info-buttons {
    width: 100% !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    overflow-x: scroll !important;
  }
  .fields-columns {
    display: flex !important;
    flex-direction: column !important;
  }
  .equal-width-2 {
    display: flex !important;
  }
  .multi-inputs {
    width: 100%;
    overflow-x: auto;
  }
  .calender-fields {
    display: flex !important;
    flex-direction: column !important;
    gap: 30px !important;
  }
  li:not(:last-child) .bg-step.done::after {
    rotate: 90deg !important;
    transform: rotate(0deg) !important;
    right: -19px !important;
  }
  .create-button {
    display: flex !important;
  }
  .container {
    & .single-card {
      .select-container {
        visibility: visible !important;
      }
    }
  }
  .cards-container {
    width: 82% !important;
  }
  .sxw-theme {
    & .container {
      & .card-main-container {
        & .sxw-card-header {
          grid-template-columns: 80% 57px !important;
        }
        & .sxw-card-footer {
          & .actions {
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .sxw-theme .container .card-main-container .sxw-card-footer .actions > i {
    top: 0rem !important;
  }
  .image-clickable {
    display: none !important;
  }
  .vat-input {
    width: 100%;
    sxw-dropdown-input {
      width: 30%;
    }
    &__input {
      flex: 1;
      margin-left: 2px;
      width: 70%;
    }
  }
  .p-dropdown {
    .p-dropdown-trigger {
      width: 1.5rem !important;
    }
    .p-dropdown-clear-icon {
      right: 1.5rem !important;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sxw-theme {
    & .container {
      & .card-main-container {
        & .sxw-card-header {
          grid-template-columns: 82% 57px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .steper {
    overflow: unset !important;
  }
  .steper > ul > li {
    width: 0% !important;
  }
  .steper > ul {
    gap: 75px !important;
    padding-left: 15px;
  }
  li:not(:last-child) .bg-step.done::after {
    rotate: 90deg !important;
    transform: rotate(0deg) !important;
    right: -19px !important;
  }

  .dynamic-form {
    &-container {
      flex-direction: column;
    }
    &-control {
      @for $col from 1 through 12 {
        &.col-#{$col} {
          flex-basis: unset !important;
          max-width: unset !important;
        }
      }
    }
  }
  .sxw-theme.form-container {
    .form-container,
    .form-container-config {
      padding-inline: 0px;
      padding-left: 12px;
      padding-right: 5px;
    }
  }
  .car-info-buttons {
    width: 100% !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    overflow-x: scroll !important;
  }
  .switch-input {
    width: 100%;
  }
  .advanced {
    .switch-input {
      justify-content: space-between !important;
    }
  }
  .path {
    height: 50px;
  }
  .switch-input {
    width: 100%;
  }

  .summary-cards .summary-header {
    width: 98.5% !important;
  }
  .tags-list {
    overflow-x: auto;
  }
  .m-b-5 {
    display: flex !important;
    flex-direction: column !important;
  }
  .m-b-15 {
    overflow-x: auto !important;
  }
  .actions-list-handle {
    visibility: visible !important;
  }
  app-list-article .cards-container {
    margin-left: 25px !important;
  }
}

.booking-reservation {
  > .form-container {
    height: 100%;
  }
  .reservation-rate-item-qty .p-dropdown {
    border-radius: 10px !important;
    min-height: 2rem !important;
    .p-dropdown-label {
      padding: 0.2rem 0.2rem 0.2rem 0.6rem !important;
    }
    .p-dropdown-trigger {
      width: 2rem !important;
    }
  }

  .reservation-rate-item-qty.checked .p-dropdown {
    background: #e2007a;
    .p-dropdown-label,
    .p-dropdown-trigger {
      color: #fff;
    }
  }

  .reservation-rate-item-qty.readonly .p-dropdown {
    background-color: #f07fbc;
    cursor: not-allowed;
  }

  .plan.checked button {
    background-color: #e2007a;
    &:hover {
      background-color: #f07fbc;
    }
    &:after {
      content: "✔";
      font-family: "Open Sans";
    }
  }

  .group-dropdown,
  .vehicle-dropdown {
    position: relative;
    .p-dropdown-item {
      margin: 0.5rem 1rem !important;
      padding: 0.5rem 1rem !important;
      border-radius: 6px !important;
      border: 1px solid #ddd !important;
    }

    .p-dropdown-item.p-highlight {
      color: #ffffff !important;
      background: #e2007a !important;
    }
    .p-overlay {
      z-index: 10;
      width: 100%;
      top: 0 !important;
      left: 0 !important;
    }
  }
}

.booking-reservation-popup.p-dialog {
  font-family: "Open Sans", sans-serif;
  font-weight: unset !important;
  .p-dialog-header {
    background: #1c2230;
    color: #fff;
  }

  .p-dialog-content {
    padding-bottom: 80px;
    background: #fafbfc;
    .centered .cards-container {
      width: 99%;
    }
  }

  .filter-search {
    margin-left: 0px !important;
  }
  .cancel-btn button {
    background: #595252 !important;
  }

  .accordion-header {
    background-color: unset !important;
  }
}

.reservation-summary-container {
  .p-checkbox {
    .p-checkbox-box.p-highlight.p-disabled {
      background: #f07fbc !important;
      border-color: #f07fbc !important;
    }
    .p-checkbox-box.p-highlight,
    .p-checkbox-box.p-highlight:hover,
    .p-checkbox-checked {
      background: #e2007a;
      border-color: #e2007a;
    }
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background: #e2007a;
    border-color: #e2007a;
  }

  .p-inputtext {
    width: 40px;
    height: 30px;
    border: none;
    padding: 0;
    margin: 0;
    // font-size: 0.8em;
  }
  .p-inputtext:enabled:focus,
  .p-inputtext:hover,
  .p-inputtext:active {
    border: none;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
    border: none !important;
  }

  .subtotal-tag-number {
    input:hover {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
}
